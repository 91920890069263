.error-prompt {

    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.8);
    left: 0;
    top: 0;
    z-index: 800;

    .content {
        max-width: 400px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        i {
            display: inline-block;
            margin-bottom: 30px;

            svg {
                width: 50px;
                height: auto;
            }
        }

        p {
            color: $white;
            margin-bottom: 30px;
        }
    }


}