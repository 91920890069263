.snackbar {
  position: absolute;
  display: flex;
  align-items: center;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 4px;
  border-radius: 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  code {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    line-height: 32px;
    position: relative;

    &.blink {
      background-color: #6a2f2f;
      height: 13px;
      width: 13px;
      margin-left: 2px;
      animation: blink 1.5s infinite ease-in-out;

      &+span {
        line-height: 15px;
        padding-left: 5px;
      }
    }

    .peer-name {
      display: none;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: auto;
      transform: translate(-50%, -50%);

      path {
        stroke: #fff;
      }
    }

    img {
      height: 100%;
      widows: 100%;
      border-radius: 50%;
      position: relative;
      z-index: 1;
    }

    &.primary-bg {
      background-color: $cornflowerBlue;
    }

    &.secondary-bg {
      background-color: $blood-red;
    }

    &.tertiary-bg {
      background-color: $orangePeel;
    }

    &.gradient-bg {
      @include bg-gradient-l2r($shamrock, $lightSeaGreen);
    }
  }

  &.new-user {
    z-index: 401;
  }


  span {
    vertical-align: middle;
    font-size: 14px;
    color: $white;
    padding: 0 10px;
    line-height: 15px;

    b {
      font-weight: 600;
    }
    a{
      color:inherit;
      text-decoration: underline;
      font-size: inherit;
    }
  }

  .close-icon{
    margin: 0px 10px 0px 0px;
    cursor: pointer;
    svg{
      height: 9px;
      width: auto;
      vertical-align: middle;
    }
  }


  &.recording-indicator {
    bottom: 30px;
    top: initial;
    transform: none;
    right: 185px;
    bottom: 27px;
    left: initial;
    transition: 0.2s transform ease-out;
    z-index: 10;
    padding: 9px;

  }

  &.upgrade-notification{
    button{
      margin-left: auto;
      padding: 5px 10px;
      font-size: 14px;
    }
  }
  &.join-request,
  &.upgrade-notify {
    button {
      padding: 6px 20px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
  }


  &.join-request {

    button {

      &.reject-btn {
        background-color: transparent;
        color: $white;
        text-decoration: underline;
        border: none;
        padding: 6px;
        margin-right: 10px;

      }
    }

  }

  &.upgrade-notify {
    .upgrade-btn {
      border: none;
    }

  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}