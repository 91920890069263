label {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
}

input {
  width: 442px;
  height: 48px;
  padding: 13px 20px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: $nightRider;
  border: 2px solid $veryLightGrey;
  @include border-radius(8px);
  background-color: $white;
  @include transition(all 0.3s linear);

  @include placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.56;
    letter-spacing: -0.2px;
    color: $nobel;
  }

  @include placeholder-focus {
    font-size: 0;
  }

  &:hover {
    border-color: $nobel;
  }

  &:focus {
    border-color: $cornflowerBlue;
  }

  &.error {
    border-color: $orangePeel;

    &+.error-message {
      visibility: visible;

    }
  }

  &.disabled {
    pointer-events: none;
    border-color: $whisper;
    background-color: $aliceBlue;

  }

  &+.error-message {
    visibility: hidden;
    display: table;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.79;
    letter-spacing: -0.18px;
    color: $tenne;
  }
}