h1 {
  font-size: 96px;
  font-weight: 600;
  line-height: 0.99;
  letter-spacing: -3px;
}
h2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: -1.85px;
}
h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: -1.48px;
}
h4 {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -1.05px;
}
h5 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.74px;
}
h6 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.62px;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.56;
  letter-spacing: -0.2px;
}
