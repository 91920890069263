a {
  padding: 0 9px 0 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: $ceruleanBlue;
  border: 1px solid transparent;
  @include border-radius(4px);
  @include transition(all 0.3s linear);
  &:hover {
    text-decoration: underline;
  }
  &:active {
    border-color: $whisper;
    text-decoration: underline;
  }
  &:visited {
    color: $smalt;
    text-decoration: underline;
  }
  &.disabled {
    color: $nobel;
    text-decoration: none;
    pointer-events: none;
  }
}
