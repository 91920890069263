.circular-preview {
  
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    // transition: transform 0.5s ease-in-out;
    width: 560px;
    height: 560px;
    padding: 33px;
    border: 2px solid rgba($white, 0.28);
    @include border-radius(50%);
    @include background-clip(content-box);
    background-color: rgba($black, 0.7);

    @include mq('desktop-medium',max){
        @include transform(translate(-50%, -50%) scale(0.7));
    }
    @include mq('phone', max) {
        @include transform(translate(-50%, -50%) scale(0.5));
    
    }
    // @include mq('tablet-wide',max){
    //     @include transform(translate(-50%, -50%) scale(0.7));
    // }
    @media only screen and (max-height: 750px){
        @include transform(translate(-50%, -50%) scale(0.9));
    }

    @media only screen and (max-height: 700px){
        @include transform(translate(-50%, -50%) scale(0.8));
    }

    @media only screen and (max-height: 600px){
        @include transform(translate(-50%, -50%) scale(0.7));
    }



    // &.animate-wave{
    //     &::before,&::after{
    //         content:"";
    //         position: absolute;
    //         z-index: -1;
    //         height: calc(100% + 20px);
    //         width:calc(100% + 20px);
    //         background: rgba(0,0 ,0 , 0.5);
    //         top:0;
    //         left:0;
    //     }
    // }

    
}