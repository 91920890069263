%dropdown-list {

  >ul {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 8px 4px;
    border: 1px solid $whiteSmoke;
    overflow: auto;
    @include border-radius(8px);
    background-color: $white;
    @include box-shadow(0 7px 17px 0 rgba($black, 0.08));
    @include transform(translateY(-6px));
    @include transition(all 0.2s linear);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    max-height: 110px;


    li {
      padding: 0 15px;
      font-size: 16px;
      font-weight: 400;
      line-height: 2.81;
      letter-spacing: -0.2px;
      color: $nightRider;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      @include transition(background-color 0.3s linear);

      &:hover {
        @include border-radius(4px);
        background-color: $skyblue;
      }
    }
  }
}

.dropdown-primary {
  position: relative;
  width: 442px;

  i {
    position: absolute;
    top: 50%;
    right: 14px;
    border-top: 6px solid $cornflowerBlue;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    @include border-radius(2px);
    @include transform(translateY(-50%));
    cursor: pointer;
  }

  @extend %dropdown-list;

  >input {
    &:focus {
      &~ul {
        @include transform(translateY(0));
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
  }

  .input-field{
    height: 48px;
    padding: 10px 30px 10px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.56;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: -0.2px;
    color: $nightRider;
    border: 2px solid $veryLightGrey;
    @include border-radius(8px);
    background-color: $white;
    @include transition(all 0.3s linear);
    cursor: pointer;

    >span{
      font-size: 16px;
      font-weight: 400;
      line-height: 1.56;
      letter-spacing: -0.2px;
      color: $txt-color-tertiary;
    }
    &:hover {
      border-color: $nobel;
    }
    &:focus {
      border-color: $cornflowerBlue;
      outline: none;
    }
  }

  &.opened {
    >ul {
      @include transform(translateY(0));
      opacity: 1;
      visibility: visible;
      pointer-events: all;
     
    }
  }

  &.disabled {
    pointer-events: none;

    input {
      border-color: $whisper;
      background-color: $aliceBlue;
    }

    i {
      border-top-color: $veryLightGrey;
    }
  }
}

.dropdown-secondary {
  @extend %dropdown-list;

  >ul {
    min-width: 250px;
    top: 100%;
    text-align: left;
  
    
  }
  &.opened {
    >ul{
      @include transform(translateY(0));
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

  
  }

}