.peer-details {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 45px;
    width: 45px;
    transform: translate(-50%, -50%);
    background-color: $cornflowerBlue;
    border-radius: 50%;


    &::after {
      content: attr(data-initial);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      visibility: hidden;
      font-weight: 600;
      text-transform: uppercase;
    }



    svg {
      // visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: auto;
  

      &.dial-in-icon {
        transform: translate(-50%, -50%) rotate(135deg);
        visibility: hidden;
      }

      &.guest-icon {
        visibility: hidden;
      }
    }

    img {
      position: absolute;
      height: 100%; 
      width: 100%;
      left: 0;
      visibility: hidden;
      top: 0;
      right: 0;
      border-radius: 50%;
      bottom: 0;
      object-fit: cover;


    }

    &.profile-picture {
      img {
        visibility: visible;
      }
    }

    &.dial-in {
      svg.dial-in-icon {
        visibility: visible;
      }
    }

    &.guest {
      svg.guest-icon {
        visibility: visible;
      }
    }

    &.peer-initial {
      &::after {
        visibility: visible;
      }
    }

  }