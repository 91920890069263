// Colors Scheme used
$white: #ffffff;
$black: #000000;
$light-black:#1f1f1f;
$whiteSmoke: #f7f7f7;
$aliceBlue: #f8fafc;

$orangePeel: #f9a102;
$lemonPeel:#ffe2ad;
$whisperLight: #eeeeee;
$whisper: #e5e5e5;
$tenne: #ce6601;
$veryLightGrey: #c9c9c9;
$cerulean: #0e73ab;
$smalt: #0d3c91;
$persianGreen: #0c9d8c;
$irisBlue: #16a9c5;
$lightSeaGreen: #2abdb6;
$ceruleanBlue: #2763ce;
$mountainMeadow: #27bc82;
$shamrock: #33d093;
$nightRider: #353535;
$cornflowerBlue: #6285ff;
$skyblue:#e3e8f5;
$darkblue:#2f50c2;
$inkblue:#2763ce;
$nobel: #969696;
$ash: #8c8c8c;
$blood-red:#f54736;
$fb-blue:#1877f2;
$hover-blue:#4684ff;


//Text color scheme
$txt-color-primary:#000000;
$txt-color-secondary:#353535;
$txt-color-tertiary:#969696;
$txt-color-quaternary:#656565;
$tag-text-color:#61759d;

//Device icon color
$device-icon-primary:#656565;

//Signal indication
$signal-indication-off:#eeeeee;
$signal-indication-on:#6285ff;

//Border color scheme
$border-color-primary:#e5e5e5;
$border-color-secondary:#e0e0e0;
$border-color-tertiary:#f2f2f2;










