%btn {
  padding: 12px 35px;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid transparent;
  @include border-radius(24px);
  @include transition(box-shadow 0.2s linear);
  cursor: pointer;
  text-decoration: none;
}
.btn {
  &-primary {
    @extend %btn;
    color: $white;
    border: 0;
    @include bg-gradient-l2r($shamrock, $lightSeaGreen);
    @include box-shadow(0 5px 13px 0 rgba($shamrock, 0.32));
    &:hover {
      @include bg-gradient-l2r($mountainMeadow, $irisBlue);
      @include box-shadow(0 5px 13px 0 rgba($shamrock, 0.6));
      text-decoration: none;
    }
    &.pressed {
      @include bg-gradient-l2r($persianGreen, $cerulean);
      @include box-shadow(0 5px 13px 0 rgba($shamrock, 0.6));
    }
    &.disabled {
      color: $nobel;
      background-image: none;
      background-color: $whisper;
      @include box-shadow(none);
      cursor: default;
    }
  }
  &-secondary {
    @extend %btn;
    color: $ceruleanBlue;
    border-color: $whisper;
    background-color: $white;
    @include box-shadow(0 5px 10px 0 rgba($veryLightGrey, 0.54));
    &:hover {
      background-color: $whisperLight;
      @include box-shadow(0 5px 23px 0 rgba($veryLightGrey, 0.54));
      text-decoration: none;
    }
    &.pressed {
      background-color: $whisper;
      @include box-shadow(0 5px 23px 0 rgba($veryLightGrey, 0.54));
    }
    &.disabled {
      color: $nobel;
      border-color: $whisper;
      @include box-shadow(none);
      cursor: default;
      pointer-events: none;
    }
  }

  &-tertiary {
    @extend %btn;
    color: $white;
    border-color: $cornflowerBlue;
    background-color: $cornflowerBlue;
    // @include box-shadow(0 5px 10px 0 rgba($cornflowerBlue, 0.54));
    &:hover {
      background-color: $hover-blue;
      // @include box-shadow(0 5px 5px 0 rgba($hover-blue, 0.54));
      text-decoration: none;
      border-color: $hover-blue;
    }
    &.pressed {
      background-color: $hover-blue;
      // @include box-shadow(0 0px 5px 5px rgba($hover-blue, 0.54));
    }
    &.disabled {
      color: $nobel;
      background-image: none;
      background-color: $whisper;
      @include box-shadow(none);
      cursor: default;
    }
  }
 
}
