// border radius
//
// USAGE:
// @include border-radius(4px)
//
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// box shadow
//
// USAGE:
// @include box-shadow(0 0 20px 0 rgba(255,255,255,.1))
//
@mixin box-shadow($shadows...) {
  -moz-box-shadow: $shadows;
  -webkit-box-shadow: $shadows;
  box-shadow: $shadows;
}

// background clip
//
// USAGE:
// @include background-clip($clip);
//
@mixin background-clip($clip) {
  -webkit-background-clip: $clip;
  -moz-background-clip: $clip;
  -o-background-clip: $clip;
  background-clip: $clip;
}

// transform
//
// USAGE:
// @include transform(translate(10px, 10px));
//
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// transition
//
// USAGE:
// @include transition(translate(10px, 10px));
//
@mixin transition($values...) {
  -moz-transition: $values;
  -ms-transition: $values;
  -o-transition: $values;
  -webkit-transition: $values;
  transition: $values;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

// width max content
//
// USAGE:
// @include width-max-content();
//
@mixin width-max-content() {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: -ms-max-content;
  width: max-content;
}

// width min content
//
// USAGE:
// @include width-min-content();
//
@mixin width-min-content() {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: -ms-min-content;
  width: min-content;
}

// retina image
//
// USAGE:
// @include background-image-retina('/images/icons/sidebar/icon-add-hover', png, 12px, 12px)
//
@mixin background-image($file, $type, $dimensions, $position, $repeat) {
  background-image: url($file+"."+$type);
  -webkit-background-size: $dimensions;
  -moz-background-size: $dimensions;
  -o-background-size: $dimensions;
  background-size: $dimensions;
  background-position: $position;
  background-repeat: $repeat;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-moz-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file+"@2x."+$type);
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (-moz-min-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 288dpi),
  only screen and (min-resolution: 3dppx) {
    & {
      background-image: url($file+"@3x."+$type);
    }
  }
}

@mixin multi-background-image($file1,
  $type1,
  $dimensions1,
  $position1,
  $repeat1,
  $file2,
  $type2,
  $dimensions2,
  $position2,
  $repeat2) {
  background-image: url($file1+"."+$type1), url($file2+"."+$type2);
  -webkit-background-size: $dimensions1, $dimensions2;
  -moz-background-size: $dimensions1, $dimensions2;
  -o-background-size: $dimensions1, $dimensions2;
  background-size: $dimensions1, $dimensions2;
  background-position: $position1, $position2;
  background-repeat: $repeat1, $repeat2;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-moz-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file1+"@2x."+$type1), url($file2+"@2x."+$type2);
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (-moz-min-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 288dpi),
  only screen and (min-resolution: 3dppx) {
    & {
      background-image: url($file1+"@3x."+$type1), url($file2+"@3x."+$type2);
    }
  }
}

@mixin remove-background-image() {
  background-image: none;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-moz-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    & {
      background-image: none;
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (-moz-min-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 288dpi),
  only screen and (min-resolution: 3dppx) {
    & {
      background-image: none;
    }
  }
}

// background gradient: top to bottom
//
// USAGE:
// @include bg-gradient-t2b(start colour, end colour);
//
@mixin bg-gradient-t2b($start-colour, $end-colour) {
  //background-color: $start-colour;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from($start-colour),
      to($end-colour));
  background-image: -webkit-linear-gradient(top, $start-colour, $end-colour);
  background-image: -moz-linear-gradient(top, $start-colour, $end-colour);
  background-image: -ms-linear-gradient(top, $start-colour, $end-colour);
  background-image: -o-linear-gradient(top, $start-colour, $end-colour);
  background-image: linear-gradient(to bottom, $start-colour, $end-colour);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');
}

// background gradient: left to right
//
// USAGE:
// @include bg-gradient-l2r(start colour, end colour);
//
@mixin bg-gradient-l2r($start-colour, $end-colour) {
  //background-color: $start-colour;
  background-image: -webkit-gradient(linear,
      left,
      right,
      from($start-colour),
      to($end-colour));
  background-image: -webkit-linear-gradient(left, $start-colour, $end-colour);
  background-image: -moz-linear-gradient(left, $start-colour, $end-colour);
  background-image: -ms-linear-gradient(left, $start-colour, $end-colour);
  background-image: -o-linear-gradient(left, $start-colour, $end-colour);
  background-image: linear-gradient(to right, $start-colour, $end-colour);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');
}

// word wrap
//
// USAGE:
// @include white-space(pre-wrap);
//
@mixin white-space($type) {
  white-space: -moz-$type;
  /* Mozilla, since 1999 */
  white-space: -$type;
  /* Opera 4-6 */
  white-space: -o-$type;
  /* Opera 7 */
  white-space: $type;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

// text-overflow
//
// USAGE:
// @include text-overflow(ellipsis);
//
@mixin text-overflow($type) {
  -ms-text-overflow: $type;
  -o-text-overflow: $type;
  text-overflow: $type;
}

// placeholder for input fields & text areas
//
// USAGE:
// @include placeholder{};
//

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// placeholder for input fields & text areas
@mixin placeholder-focus {
  &:focus::-webkit-input-placeholder {
    @content;
  }

  &:focus:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }

  &:focus::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  &:focus:-ms-input-placeholder {
    @content;
  }
}

// Animations
// Usage
// @mixin move-the-object {
//  @include animate(move-the-object, 0.6s, infinite, normal);
//} */
@mixin animate($name,
  $duration,
  $iteration,
  $delay,
  $direction,
  $fill,
  $function) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -o-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
  -webkit-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -o-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
  -webkit-animation-timing-function: $function;
  -moz-animation-timing-function: $function;
  -o-animation-timing-function: $function;
  animation-timing-function: $function;
}

// @include keyframes(move-the-object) {
//  0%   { left: 100px; }
//  100% { left: 200px; }
//}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex flow
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

// font family
// usage @include font(lato, lato/semibold, 400, normal);
@mixin font($name, $src, $weight, $style) {
  @font-face {
    font-family: $name;
    src: url("../fonts/"+$src+".woff2") format("woff2"),
      url("../fonts/"+$src+".woff") format("woff"),
      url("../fonts/"+$src+".otf") format("opentype");
    font-weight: $weight;
    font-style: $style;
  }
}

// Clearfix for floats
// Usage @include clearfix
@mixin clearfix {
  content: "";
  clear: both;
  display: table;
}


//Visibility of tooltips
@mixin tooltip{
  &:hover{
    .tooltip{
      display: block;
    }
  }

}

// responsive
//*Usage
// @include mq('mobile') {
//   display: block;
// }
// @mixin mq($break) {
//   $value: map-get($breakpoints, $break);
//   $mobile: map-get($breakpoints, "mobile");
//   $tablet: map-get($breakpoints, "tablet");

//   @if $value == null {
//     @error "`#{$break}` is not a valid breakpoint name.";
//   } @else if $value == $mobile or $value == $tablet {
//     @media (max-width: $value) {
//       @content;
//     }
//   }
// }

$breakpoints: ("phone": 410px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet-medium": 768px,
  "tablet":992px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-medium":1300px,
  "desktop-wide": 1440px);

//*Usage
// @include mq(phone,max) {
//   display: block;
// }

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}