.config-box-holder {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &.permissions-popup{
    z-index: 400;
  }
}

.config-box {
  position: absolute;
  min-width: 480px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 20px;
  border-radius: 12px;
  background-color: $white;
  padding: 8px 8px 0 8px;

  .topbar {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-icon {
      cursor: pointer;

      svg {
        vertical-align: middle;
      }
    }
  }

  .footer {
    margin-top: 30px;
    padding: 25px;
    border-top: 1px solid $border-color-primary;
  }

  @include mq("desktop-medium", max) {
    @include transform(translate(-50%, -50%) scale(0.9));
  }

  @include mq("desktop", max) {
    @include transform(translate(-50%, -50%) scale(0.8));
  }

  @include mq("tablet-wide", max) {
    @include transform(translate(-50%, -50%) scale(0.7));
  }

  @media only screen and (max-height: 750px) {
    @include transform(translate(-50%, -50%) scale(0.9));
  }
  @media only screen and (max-height: 700px) {
    @include transform(translate(-50%, -50%) scale(0.8));
  }

  @media only screen and (max-height: 600px) {
    @include transform(translate(-50%, -50%) scale(0.7));
  }
}
