.overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba($color: #000000, $alpha: 0.8);
    padding: 0 15px;

    &.loader-overlay {

        .loader-wrapper{
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            color: rgba($color: #fff, $alpha: 0.8);
            span{
                display: block;
                font-size: 30px;
            }
        }
        .loader{

            border: 3px solid rgba($color: #fff, $alpha: 0.4);
            /* Light grey */
            border-top: 3px solid $veryLightGrey;
            /* Blue */
            border-radius: 50%;
            width: 100px;
            height: 100px;
            animation: spin 2s linear infinite;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 30px;
        }

       

    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}