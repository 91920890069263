

.tooltip,.pseudo-tooltip {
    text-transform: capitalize;
    position: absolute;
    background-color: $black;
    padding: 3px 10px;
    color: $white;
    font-size: 12px;
    border-radius:15px;
    font-weight: 500;
    opacity: 0.7;
    white-space: nowrap;
    display: none;
    z-index: 1;
    


    // &:after {
    //     content: "";
    //     width: 0;
    //     height: 0;
    //     position: absolute;
    // }

    &.up {
        top:-30px;
        left:50%;
        transform: translate(-50%,0);
        // &:after {
        //     bottom: -5px;
        //     border-left: 5px solid transparent;
        //     border-right: 5px solid transparent;
        //     border-top: 5px solid rgba(0, 0, 0, 1);
        //     left:50%;
        //     transform: translate(-50%,0);


        // }

    }

    &.down{
        bottom:-25px;
        left:50%;
        transform: translate(-50%,0);
        // &:after{
        //     top: -5px;
        //     border-left: 5px solid transparent;
        //     border-right: 5px solid transparent;
        //     border-bottom: 5px solid rgba(0, 0, 0, 1);
        //     left:50%;
        //     transform: translate(-50%,0);
        // }
    }

    &.left{
        left:-45px;
        bottom:50%;
        transform: translate(0,-50%);
        // &:after{
        //     right: -4px;
        //     border-bottom: 5px solid transparent;
        //     border-top: 5px solid transparent;
        //     border-left: 5px solid rgba(0, 0, 0, 1);
        //     top:50%;
        //     transform: translate(0,-50%);
        // }
    }

    &.right{
        right:-45px;
        bottom:50%;
        transform: translate(0,-50%);
        // &:after{
        //     left: -4px;
        //     border-bottom: 5px solid transparent;
        //     border-top: 5px solid transparent;
        //     border-right: 5px solid rgba(0, 0, 0, 1);
        //     top:50%;
            
        //     right: 50%;
        //     transform: translate(0,-50%);
        // }
    }

}