%profile-settings {
    font-size: 0;
    // line-height: 48px;
    position: relative;

    .profile-avatar {
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        line-height: 1;
        text-decoration: none;
        font-size: 0;
        outline: none;
        border: none;

        code {
            position: relative;
            top: initial;
            left: initial;
            transform: none;
            height: 30px;
            width: 30px;
            font-size: 12px;
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
            background-color: rgba($color: #000000, $alpha: 0.5);

            img {}

            &.user-initial {
                background-color: $cornflowerBlue;
            }
    

        }

        .peer-name {
            display: none;
        }

        .peer-name {
            display: none;
        }

        >i {
            display: inline-block;
            vertical-align: text-top;
            border-top: 6px solid $cornflowerBlue;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-radius: 2px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            cursor: pointer;

        }
    }


    ul {
        right: -20px;
        left: initial;
        bottom: initial;
        top: 35px;
        transform: translateY(-6px);
        max-height: initial;
        width: 250px;
        padding: 5px 0;

        li {
            width: calc(100% - 10px);
            padding: 0px 10px;
            margin: 0 auto;

            i{
                margin-right: 20px;
            }

            &:first-child {
                border-bottom: 1px solid $whisperLight;
                padding: 5px 15px 10px 15px;
                margin-bottom: 5px;
                width: 100%;
                i{
                    margin-right: 5px;
                }

                a {
                    text-decoration: none;
                    color: inherit;
                    padding: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    span {
                        font-weight: 500;
                    }

                }

                &:hover {
                    background-color: initial;
                }
            }

            svg,
            img {

                vertical-align: middle;
            
            }

            svg {
                width: 20px;
                height: 20px;
            }

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

        }

    }


    &.opened {

        ul {
            transform: translateY(0px);
        }
    }


}
