.call-duration-indicator {
      position: absolute;
      z-index: 10;
      bottom: 25px;
      right: 75px;
      color: $white;
      background: rgba(0, 0, 0, 0.7);
      min-width: 100px;
      font-size: 14px;
      text-align: center;
      padding: 10px 15px;
      border-radius: 50px;
      transition: transform 0.2s ease-out;

      @include mq(phone, max) {
            display: none;

      }
}