.radiobox {
    display: block;
    margin-bottom: 15px;

    input[type="radio"] {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked {
            &+label {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    transform: translate(-50%, -50%);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    background-color: $cornflowerBlue;
                }

                &::before {

                    border: 2px solid $cornflowerBlue;
                }
            }

            &:hover {
                &+label {

                    &::after {
                        background-color: $ceruleanBlue;
                    }

                    &::before {

                        border: 2px solid $ceruleanBlue;
                    }


                }
            }
        }

    }

    label {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        line-height: 24px;
        color: $nobel;
        user-select: none;

        &::before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid $veryLightGrey;
            border-radius: 50%;
            height: 22px;
            width: 22px;
            // padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;

        }

        &:hover {

            &::before {
                border: 2px solid $nobel;
            }

        }

    }

    &.disabled {
        input[type="radio"] {
            pointer-events: none;

            &:checked {
                &+label {

                    &::after {
                        background-color: $nobel;
                    }

                    &::before {

                        border: 2px solid $nobel;
                    }
                }
            }
        }

        label {
            pointer-events: none;

            &::before {
                border: 2px solid $whisper;
                background-color: $aliceBlue;
            }
        }
    }

}