@-webkit-keyframes video-popup {
    0% {
      opacity: 0;
      top:45%;

    }

    100% {
      opacity: 1;
      top: 50%

    }
  }
@keyframes video-popup {
    0% {
      opacity: 0;
      top:45%;

    }

    100% {
      opacity: 1;
      top: 50%;

    }
  }


.video-wrapper {
    width: 80%;
    max-width: 1400px;
    animation: video-popup 0.7s 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    .cancel-btn {
        position: absolute;
        top: 5px;
        right: 7px;
        cursor: pointer;

        svg {
            width: 15px;
            height: auto;
            vertical-align: middle;
            border-radius: 3px;

            path {
                fill: $white;

                &:last-child {
                    fill: $black;
                }
            }
        }

    }

    video {
        width: 100%;
        height: 100;
        outline: none;


        &:focus {
            outline: none;
        }
    }

} 